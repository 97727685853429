import { useState } from 'react'
import { SingleValue } from 'react-select'

import { Currency } from 'core/remodel/types/common'
import { useAuthStore } from '@/store/authStore'

export function useDisplayedCurrency() {
  const database = useAuthStore((state) => state.database)
  const [displayedCurrency, setDisplayedCurrency] = useState<Currency>(database?.ExRate.BaseCurrency ?? Currency.USD)

  const handleDisplayedCurrency = (option: SingleValue<{ label: Currency; value: Currency }>) => {
    if (option !== null) {
      setDisplayedCurrency(option.value)
    }
  }

  return {
    displayedCurrency,
    handleDisplayedCurrency
  }
}
