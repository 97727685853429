import getSymbolFromCurrency from 'currency-symbol-map'
import useSWR from 'swr'

import { Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { formatNumber, formatSymbol } from '@/utils/formatter'
import useIsMobile from '@/hooks/useIsMobile'
import { useAuthStore } from '@/store/authStore'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import { PrivacyField } from '@/components/PrivacyField'

type Stat = {
  label: string
  value: number
  unit?: string
}

interface SummaryProps {
  stats: Stat[]
  className?: string
}

export default function Summary({ stats, className }: SummaryProps) {
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const isMobile = useIsMobile()

  return (
    <div className={cn('flex flex-col rounded bg-white pl-4 pt-2 text-text shadow md:pt-0 lg:flex-row', className)}>
      {stats.map(({ label, value, unit }, index) => {
        const isShortening = value >= 1e9
        const displayValue = isMobile
          ? formatSymbol(value, unit as Currency)
          : `${unit} ${formatNumber(value, preferences.numberFormat, { digits: 0 })}`
        const tooltipValue = isMobile
          ? formatSymbol(value, unit as Currency, 2)
          : `${unit} ${formatNumber(value, preferences.numberFormat, {
              digits: 2,
              isShortening: false
            })}`
        return (
          <div
            key={index}
            className={
              'flex flex-row justify-between pr-2 md:my-4 md:flex-1 md:flex-col md:space-y-2 md:border-l md:border-grey md:px-4'
            }
          >
            <h6 className={'text-sm uppercase md:text-lg'}>{label}</h6>
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <span className={cn(value < 0 && 'text-error')}>
                  <PrivacyField className={' text-sm font-bold md:text-xl'}>{displayValue}</PrivacyField>
                </span>
              </TooltipTrigger>
              {isShortening && (
                <TooltipContent side={'top'} sideOffset={8}>
                  <PrivacyField className={'text-xs font-medium text-white'}>{tooltipValue}</PrivacyField>
                </TooltipContent>
              )}
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}
