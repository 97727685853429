import { Children, createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAnimate } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'

import { cn } from '@/utils/classnames'
import { Button } from '@/components/base'

const throttle = <T extends any[]>(callback: (...args: T) => void, delay = 500) => {
  let wait = false
  return (...args: T) => {
    if (wait) return
    wait = true
    setTimeout(() => {
      callback(...args)
      wait = false
    }, delay)
  }
}

type CarouselState = {
  gap: number
  count: number
}

const CarouselContext = createContext<CarouselState>({
  gap: 0,
  count: 0
})

type Responsive = {
  breakpoint: number
  count: number
}

const DEFAULT_GAP = 12
const DEFAULT_RESPONSIVE: Responsive[] = [
  { breakpoint: 768, count: 4 },
  { breakpoint: 0, count: 2 }
]

interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  containerClassName?: string
  gap?: number
  responsive?: Responsive[]
  defaultPage?: number
}

export function Carousel({
  containerClassName,
  gap = DEFAULT_GAP,
  responsive = DEFAULT_RESPONSIVE,
  className,
  style,
  children,
  defaultPage = 0,
  ...props
}: CarouselProps) {
  const [scope, animate] = useAnimate<HTMLDivElement>()
  const [minWidth, setMinWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [page, setPage] = useState(defaultPage)
  const total = Children.count(children)
  const count = useMemo(() => {
    const sorted = [...responsive].sort((a, b) => a.breakpoint - b.breakpoint)
    let result = 1
    sorted.forEach((r) => {
      if (minWidth >= r.breakpoint) {
        result = r.count
      }
    })
    return result
  }, [minWidth, responsive])
  const maxPage = useMemo(() => {
    return total - count >= 0 ? total - count : 0
  }, [total, count])

  const prev = useCallback(() => {
    setPage((prev) => (prev === 0 ? prev : prev - 1))
  }, [])

  const next = useCallback(() => {
    setPage((prev) => (prev === maxPage ? prev : prev + 1))
  }, [maxPage])

  useEffect(() => {
    if (page > maxPage) setPage(maxPage)
  }, [maxPage, page])

  useEffect(() => {
    const effectiveWidth = (containerWidth - (count - 1) * gap) / count
    if (!isFinite(effectiveWidth)) return

    const offsetPage = effectiveWidth + gap
    const x = -1 * offsetPage * page

    animate(scope.current, { x }, { type: 'tween', ease: 'easeOut' })
  }, [animate, containerWidth, count, gap, page, scope, maxPage, total])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]
      setContainerWidth(entry.contentRect.width)
    })
    observer.observe(scope.current)
    return () => observer.disconnect()
  }, [scope])

  useEffect(() => {
    const getMinWidth = throttle(() => setMinWidth(window.innerWidth))
    setMinWidth(window.innerWidth)
    window.addEventListener('resize', getMinWidth)
    return () => window.removeEventListener('resize', getMinWidth)
  }, [])

  return (
    <div className={cn('relative', containerClassName)}>
      <div className={'overflow-hidden'}>
        <CarouselContext.Provider value={{ gap, count }}>
          <div ref={scope} className={cn('flex', className)} style={{ gap, ...style }} {...props}>
            {children}
          </div>
        </CarouselContext.Provider>
      </div>
      <Button
        className={cn('absolute -left-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-1 text-primary shadow-lg', {
          hidden: page === 0 || total <= count
        })}
        onClick={prev}
      >
        <ChevronLeft />
        <span className={'sr-only'}>{'Next Slide'}</span>
      </Button>
      <Button
        className={cn('absolute -right-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-1 text-primary shadow-lg', {
          hidden: page === maxPage || total <= count
        })}
        onClick={next}
      >
        <ChevronRight />
        <span className={'sr-only'}>{'Prev Slide'}</span>
      </Button>
    </div>
  )
}
Carousel.displayName = 'Carousel'

interface CarouselItemProps extends React.HTMLAttributes<HTMLDivElement> {}

export const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(({ className, style, ...props }, ref) => {
  const { gap, count } = useContext(CarouselContext)
  const width = 100 / count
  const redundant = ((count - 1) * gap) / count
  const flexBasis = `calc(${width}% - ${redundant}px)`

  return <div ref={ref} className={cn('shrink-0', className)} style={{ flexBasis, ...style }} {...props} />
})
CarouselItem.displayName = 'CarouselItem'
